import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import SpruecheApp from './SpruecheApp';


class Home extends Component {


  render() {
    return (
      <>
        <HeroSection
          title="SV Baustetten"
          subtitle="Sprüche-App"
          imgpath="images/fussballfeld.jpg"
        />
        <div className="content">
          <SpruecheApp />

        </div>
      </>
    );
  }
}

export default Home;