import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import SpruecheApp from './SpruecheApp';
import { db } from '../../Firebase';

class Apps extends Component {

    render() {

        return <div>

            <HeroSection
                title="Apps"
                imgpath="images/Frau-mit-Smartphone.jpg"
            />
            <div className="content">

                <TextContainer
                    title="SVB-Sprüche-App als Android App"
                >

                    <h2>Sie wollen die SVB-Sprüche-App als Android Smartphone App installieren? </h2>
                    <p>Mit diesen Schritten können Sie die App installieren:</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die SVB-Sprüche-App Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>App installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>

                </TextContainer>
                <TextContainer title="SVB-Sprüche-App als IOS App">
                    <h2>Sie wollen die SVB-Sprüche-App als IOS Smartphone App installieren? </h2>
                    <p>Mit diesen Schritten können Sie die App installieren:</p>
                    <ol>
                        <li>Öffnen sie den Saferi Browser</li>
                        <li>Rufen sie die SVB-Sprüche-App Seite auf</li>
                        <li>Wahlen Sie das Symbol <i class="fas fa-external-link-alt"></i> aus</li>
                        <li>Wählen sie <strong>Zum Home-Bildschirm hinzufügen</strong> aus</li>
                        <li>Bestätigen Sie durch Hinzufügen den Vorgang</li>
                        <li>Die App wurde nun als App auf ihrem Smartphone installiert</li>
                    </ol>
                </TextContainer>
                <TextContainer title="SVB-Sprüche-App als Computer Applikation">
                    <h2>Sie wollen die SVB-Sprüche-App als Applikation auf ihrem Computer installieren?</h2>
                    <p>Mit diesen Schritten können Sie die Applikation installieren</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die SVB-Sprüche-App Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>SVB-Sprüche-App installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>
                    <p>Viel Spaß beim ausprobieren!</p>
                </TextContainer>

            </div>

        </div>;
    }
}
export default Apps;