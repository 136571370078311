import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import { db } from '../../Firebase';
import { Howl, Howler } from 'howler';

class SpruecheApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sprueche: []
        };

    }
    componentDidMount() {
        db.collection("sprueche")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                console.log(data);
                this.setState({ sprueche: data });
            });
    }

    soundPlay = (src) => {
        const sound = new Howl({
            src,
            html5: true
        })
        sound.play();
    }

    render() {
        const { sprueche } = this.state;
        return <div>

            <div className="content">
                <TextContainer title="SV Baustetten Sprüche App" bgcolor="#cccccc">
                    <h3>Alle SV Baustetten Sprüche in einer App integriert</h3>

                    <div className="sprueche-container">


                        {sprueche.map(sprueche => (
                            <div key={sprueche.uid} >
                                <div className="sprueche-container-item" onClick={() => this.soundPlay(sprueche.soundUrl)}>
                                    <div className="item-img">
                                        <img src={sprueche.imageUrl} className="item-img-icon" />
                                    </div>
                                    <div className="item-title">{sprueche.name}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                </TextContainer>
            </div>
        </div>;
    }
}
export default SpruecheApp;
