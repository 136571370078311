import firebase from 'firebase';
export var DB_CONFIG = {
    apiKey: "AIzaSyDbkxcxoNuKLt_EpiB7OKvSRiDWbK026mE",
    authDomain: "sprueche-29b03.firebaseapp.com",
    databaseURL: "https://sprueche-29b03.firebaseio.com",
    projectId: "sprueche-29b03",
    storageBucket: "sprueche-29b03.appspot.com",
    messagingSenderId: "403836443330",
    appId: "1:403836443330:web:181e96e8db2ef8148fb95b",
    measurementId: "G-B47CPPDVJH"
};
const firebaseApp = firebase.initializeApp(DB_CONFIG);

const db = firebaseApp.firestore();

export { db };

